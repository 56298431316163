<template>
    <section id="presentation-cpts">
        <div class="container">
            <div class="container">
                <div class="title-effect position-relative">
                    <h1 class="text-center title-text p-2">Explication de la CTPS</h1>
                    <div class="background-text text-center">CTPS</div>
                </div>
            </div>
            <!-- 1ère partie -->
            <div class="container mt-5">
                <div class="d-flex justify-content-start">
                    <h3 class="background-primary radius-right px-5">Définition</h3>
                </div>
                <p class="text-center">Nouvelle structure d’exercice coordonnée, les CPTS
                (Communautés Professionnelles Territoriales de Santé) permettent aux professionnels de 
                santé de se réunir localement (pas physiquement) pour répondre aux besoins de la
                population (et pas seulement de leur patientèle) et améliorer leurs conditions d’exercice
                au quotidien.
                </p>
            </div>
            <!-- 2ème partie -->
            <div class="container mt-5">
                <div class="d-flex justify-content-end">
                    <h3 class="background-primary radius-left px-5">Mais plus concrètement...</h3>
                </div>
                <p class="text-center">Les CPTS ou Communautés Professionnelles Territoriales de Santé ont été créées 
                par la loi de modernisation du système de santé de 2016, 
                et définies par la loi « Ma Santé 2022 ».

                Ce sont un mode d’organisation, sur la base du volontariat, qui permet
                aux professionnels de santé de se regrouper sur un même territoire,
                autour d’un projet médical et médico-social commun, et cela, à leur initiative.
                Peuvent s’y rassembler différents acteurs : ceux assurant des soins de premiers et
                seconds recours, mais aussi les établissements de santé publics et privés, et enfin,
                le secteur médico-social et social, afin de mieux se coordonner et structurer 
                pour fluidifier le parcours de santé.</p>
            </div>
            <!-- 3ème partie -->
            <div>
                <h3 class="text-center color-primary my-4">Ajouter phrase pertinente sur la CPTS</h3>
                <p class="text-center">La création de la CPTS se formalise par la signature d’un contrat signé 
                entre l’Assurance Maladie, l’ARS et les professionnels de santé dont le prérequis 
                est la réalisation d’unprojet de santé en lien avec les besoins du territoire.

                Cette contractualisation s’organise autour de 3 missions socles que sont :

                - L’amélioration de l’accès aux soins 
                (faciliter l’accès à un médecin traitant, améliorer la prise en charge des soins
                non programmés en ville…)

                - L’organisation d’un parcours pluri-professionnel autour du patient
                Le développement d’actions de prévention

                2 missions optionnelles peuvent être développées par la CPTS 
                pour lesquelles elle percevra un financement complémentaire au titre de l’ACI :

                Le développement de la qualité et la pertinence des soins
                L’accompagnement des professionnels de santé</p>
            </div>
            <!-- 4ème partie -->
            <div>
                <div class="d-flex justify-content-start">
                    <h3 class="background-primary radius-right px-5">Son histoire</h3>
                </div>
                <p>Les CPTS ont été créées par l’article 65 de la Loi de Modernisation du
                Système de Santé (LMSS) du 26 janvier 2016. 
                En 2018, les CPTS deviennent une mesure phare du Plan « Ma santé 2022 »
                avec un objectif de 1000 CPTS. 
                C’est l’instruction du 9 octobre 2019 qui va permettre le développement effectif des CPTS. 
                Afin de délimiter son cadre d’action et son financement, 
                un Accord Conventionnel Pluriprofessionnel (ACI) est signé par les syndicats
                des professionnels de santé et l’Union nationale des Caisses d’Assurance Maladie.
                Accord national qui évolue chaque année.
                </p>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name:"presentation-cpts"
}
</script>
<style scoped>

</style>